<template>
  <div id="natcon-payment-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <h6>* indicates required field</h6>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-row wrap>
            <v-col align="center" cols="12" sm="12" md="12">
              <v-btn
                @click.prevent="refreshPageData"
                color="#4285f4"
                elevation="30"
                shaped
                tile
                large
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                Refresh
              </v-btn>
              <v-btn
                @click.prevent="resetForm"
                color="#f25022"
                elevation="30"
                shaped
                tile
                large
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                Clear
              </v-btn>
              <router-link
                to="/natcon/non-member-payment/create"
                v-if="NonMemberButtonFlag"
              >
                <v-btn color="#228b22" tile class="white--text my-3 mr-3">
                  Click here for Non member registration
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Register for
                </h4>
                <v-select
                  :reduce="(option) => option.value"
                  :items="RegistrationTypeIdOptions"
                  :rules="RegistrationTypeIdRules"
                  v-model="RegistrationTypeId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" v-if="RegistrationTypeId >= 2">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Member
                </h4>
                <v-autocomplete
                  @change="getMemberDetails"
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <!-- :disabled="index == 0 ? true : false" -->
                  <template v-slot:selection="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <h4 class="text-primary">LOM</h4>
                <h4>{{ Lom.LomName }}</h4>
                <h4>{{ Lom.ZoneName }}</h4>
              </v-col>
              <v-col cols="12" md="2">
                <h4 class="text-primary">President details</h4>
                <h4>{{ Presidentmember.LomPresidentFullName }}</h4>
                <h4>{{ Presidentmember.LomPresidentMembershipId }}</h4>
                <v-img
                  :src="Presidentmember.MemberImagePath"
                  :alt="Presidentmember.LomPresidentFullName"
                  lazy-src="/no-image-available.png"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">Selected Participant details</h4>
                <h6>{{ selectedMember.MemberName }}</h6>
                <h6>{{ selectedMember.MembershipId }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">Selected Participant photo</h4>
                <v-img
                  :src="selectedMember.MemberImagePath"
                  :alt="selectedMember.MemberName"
                  lazy-src="/no-image-available.png"
                  max-width="100"
                  max-height="100"
                  v-if="selectedMember.MemberImagePath != ''"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Mobile Number
                </h4>
                <v-text-field
                  v-model="selectedMember.MobileNo"
                  :rules="MobileNoRules"
                  :counter="10"
                  v-mask="'##########'"
                  type="number"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.MobileNo }}</h4> -->
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Email Id
                </h4>
                <v-text-field
                  v-model="selectedMember.EmailId"
                  :rules="EmailIdRules"
                  type="email"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.EmailId }}</h4> -->
              </v-col>
              <v-col cols="12" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Gender
                </h4>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="selectedMember.Gender"
                  :items="GenderOptions"
                  :rules="GenderRules"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Food preference
                </h4>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="selectedMember.FoodChoice"
                  :items="FoodPreferenceOptions"
                  :rules="FoodPreferenceRules"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="selectedMember.MemberId > 0">
                <h4 class="text-primary">
                  Do you want GST Bill <small>(optional)</small>
                </h4>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="selectedMember.GstNumberFlag"
                  :items="GstNumberFlagOptions"
                  :rules="GstNumberFlagRules"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-if="
                  selectedMember.MemberId > 0 &&
                  selectedMember.GstNumberFlag == 1
                "
              >
                <h4 class="text-primary">
                  <span class="text-danger">*</span> GST Number
                </h4>
                <v-text-field
                  v-model="selectedMember.GstNumber"
                  :rules="GstNumberRules"
                  :counter="15"
                  v-mask="'##AAAAA####A#ZN'"
                  hint="Enter your GST Number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-if="selectedMember.MemberId > 0 && AspacFlag"
              >
                <h4 class="text-primary">
                  <span class="text-danger">*</span> T-shirt Size
                </h4>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="TshirtSize"
                  :items="TshirtSizeOptions"
                  :rules="TshirtSizeRules"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-if="selectedMember.MemberId > 0 && AspacFlag"
              >
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Passport number
                </h4>
                <v-text-field
                  v-model="PassportNumber"
                  :rules="PassportNumberRules"
                  hint="Enter your passport number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-if="selectedMember.MemberId > 0 && AspacFlag"
              >
                <h4 class="text-primary">
                  Senator number <small>(optional)</small>
                </h4>
                <v-text-field
                  v-model="SenatorNumber"
                  :rules="SenatorNumberRules"
                  hint="Applicable only if you have contributed Senator title"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-dialog v-model="PreviewPlanFlag" width="500">
              <event-plan-details
                :Plan="PreviewPlanDetails"
                @hideDialog="hidePreviewPlanPopup($event)"
                v-if="PreviewPlanFlag"
              ></event-plan-details>
            </v-dialog>

            <v-row justify="center" class="ma-3">
              <div v-for="(item, index) in Plans" :key="index">
                <v-col cols="12" sm="4" md="4" lg="4">
                  <div v-if="item.Recommended">
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards popular">
                        <div class="cards-ribbon">
                          <span>most popular</span>
                        </div>
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            {{ item.SubTotalTxt }}
                            <br />
                            <span class="cards-price-gst"
                              >+ {{ item.TaxAmountTxt }} gst</span
                            >
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                        </div>
                        <br />
                        <div class="cards-price">
                          <v-divider />
                          <h1 class="cards-price-amount">
                            <small class="cards-price-validity"
                              >Toatal Amount</small
                            >
                            ₹ {{ item.TotalAmount }}
                          </h1>
                        </div>
                        <br /><br />
                        <div class="cards-action">
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards">
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                          <h4 class="cards-subtitle">
                            {{ item.PlanDescription }}
                          </h4>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            {{ item.SubTotalTxt }}
                            <br />
                            <span class="cards-price-gst"
                              >+ {{ item.TaxAmountTxt }} gst</span
                            >
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                        </div>
                        <br />
                        <div class="cards-price">
                          <v-divider />
                          <h1 class="cards-price-amount">
                            <small class="cards-price-validity"
                              >Toatal Amount</small
                            >
                            ₹ {{ item.TotalAmount }}
                          </h1>
                        </div>
                        <br /><br />
                        <div class="cards-action">
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                            :loading="SubmitFlag"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EventPlanDetails from "@/view/pages/erp/events/natcon/EventPlanDetails.vue";

export default {
  mixins: [common],
  components: {
    EventPlanDetails,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      rows: [],

      NonMemberButtonFlag: false,
      AspacFlag: true,

      PreviewPlanFlag: false,
      PreviewPlanDetails: {},

      tableColumns1: [],
      tableColumns2: [
        { text: "Date", value: "PaidDateTxt", width: "13%" },
        { text: "Description", value: "Description", width: "35%" },
        { text: "Late Fee", value: "FineAmountTxt", width: "13%" },
        { text: "To be paid", value: "FineNotPaid", width: "13%" },
        { text: "No of members", value: "Qty", width: "13%" },
        { text: "Sub Total", value: "SubTotal", width: "13%" },
      ],

      selected: [],
      search: "",

      RegistrationTypeId: "",
      RegistrationTypeIdRules: [(v) => !!v || "Registor for is required"],
      RegistrationTypeIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "Chief delegate" },
        // { value: 2, text: "Alternate chief delegate" },
        // { value: 3, text: "Member" },
      ],

      Gender: "",
      GenderRules: [(v) => !!v || "Gender is required"],
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Other" },
      ],

      FoodPreference: "",
      FoodPreferenceRules: [(v) => !!v || "Food preference is required"],
      FoodPreferenceOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Veg" },
        { value: 2, text: "Non Veg" },
      ],

      Lom: {},

      Presidentmember: {},
      Plans: [],

      selectedPlan: {},
      selectedMember: {},

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberImageFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      OrderType: 1,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumberFlag: 2,
      GstNumberFlagRules: [],
      GstNumberFlagOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      GstNumber: "",
      GstNumberRules: [(v) => !!v || "GST number is required"],

      TshirtSize: "",
      TshirtSizeRules: [(v) => !!v || "T-Shirt size is required"],
      TshirtSizeOptions: [
        { value: "", text: "--Select--" },
        { value: "S", text: "S" },
        { value: "M", text: "M" },
        { value: "L", text: "L" },
        { value: "XL", text: "XL" },
        { value: "XXL", text: "XXL" },
        { value: "3XL", text: "3XL" },
        { value: "4XL", text: "4XL" },
        { value: "5XL", text: "5XL" },
      ],

      PassportNumber: "",
      PassportNumberRules: [(v) => !!v || "Passport number is required"],

      SenatorNumber: "",
      SenatorNumberRules: [],

      LomCode: "",
      MemberTypeId: 1,

      EventId: "",
      EventTypeId: 1,

      Order: {
        back_url: "",
        customer_name: "",
        customer_email_id: "",
        customer_mobile_no: "",
        rzp_action: "#",
        rzp_account_id: "",
        rzp_key: "bdgzbd",
        rzp_amount: "",
        rzp_order_id: "",
        rzp_buttontext: "Pay now",
        rzp_name: "JCI India Foundation",
        rzp_description: "",
        rzp_image: "",
        rzp_color: "#0096d5",
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    RegistrationTypeId: function () {
      console.log("watch RegistrationTypeId");
      this.selectedMember = {};
      var RegistrationTypeId = this.RegistrationTypeId;
      console.log("RegistrationTypeId=" + RegistrationTypeId);
      if (RegistrationTypeId != "") {
        if (RegistrationTypeId == 1) {
          var Presidentmember = this.Presidentmember;
          console.log("Presidentmember=" + JSON.stringify(Presidentmember));
          this.selectedMember = {
            MemberId: Presidentmember.LomPresidentMemberId,
            MemberName: Presidentmember.LomPresidentFullName,
            MembershipId: Presidentmember.LomPresidentMembershipId,
            MobileNo: Presidentmember.LomPresidentMobileNo,
            EmailId: Presidentmember.LomPresidentEmailid,
            Gender: Presidentmember.LomPresidentGender,
            FoodChoice: Presidentmember.LomPresidentFoodChoice,
            MemberImagePath: Presidentmember.MemberImagePath,
          };
        } else if (RegistrationTypeId >= 2) {
          this.getMemberDetails();
        } else {
        }
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var id = this.$route.query.id;
      console.log({ id });
      this.EventId = id;
      var url1 = "api/jci-program/show";
      var condition1 = {
        UserInterface: 1,
        Program: id,
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getPresidentMemberDetails();
      this.getEventsDetails();
      this.getMemberIdOptions();
    },
    showPreviewPlanPopup(tr) {
      this.PreviewPlanDetails = tr;
      this.PreviewPlanFlag = true;
    },
    hidePreviewPlanPopup() {
      this.PreviewPlanDetails = {};
      this.PreviewPlanFlag = false;
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form2.reset();
      this.selectedMember = {};
      this.selectedPlan = {};
      // this.refreshPageData();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        LomCode: this.LomCode,
        MemberType: this.MemberTypeId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      this.selectedMember = {};
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.selectedMember = {
          MemberId: member.value,
          MemberName: member.member_name,
          MembershipId: member.membership_id,
          MobileNo: member.mobile_no,
          EmailId: member.email_id,
          Gender: member.gender,
          FoodChoice: member.food_choice,
          MemberImagePath: member.photo,
        };
      }
    },
    getPresidentMemberDetails() {
      console.log("getPresidentMemberDetails is called");

      var LomId = this.LomCode;
      console.log("LomId=" + LomId);

      if (LomId != "") {
        this.SubmitFlag = true;
        this.StartupLoadingFlag = true;
        this.Presidentmember = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/show";
        var upload = {
          UserInterface: 2,
          Lom: LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.Lom = records;
              thisIns.Presidentmember = records.President;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    getEventsDetails() {
      console.log("getEventsDetails is called");

      var EventId = this.EventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        this.SubmitFlag = true;
        this.StartupLoadingFlag = true;

        this.Plans = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/lists";
        var upload = {
          UserInterface: 2,
          Program: EventId,
          ProgramType: this.EventTypeId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.Plans = records.TableData;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "Event id should not be empty";
        }
        this.toast("error", message);
      }
    },
    confirmAlert(selectedPlan) {
      var validate1 = this.$refs.form2.validate();
      this.selectedPlan = selectedPlan;
      var selectedMember = this.selectedMember;
      console.log(
        "validate1=" +
          validate1 +
          ", selectedPlan=" +
          JSON.stringify(selectedPlan) +
          ", selectedMember=" +
          JSON.stringify(selectedMember)
      );

      var MemberId = selectedMember.MemberId;
      MemberId = MemberId == (null || undefined) ? "" : MemberId;
      console.log("MemberId=" + MemberId);

      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log("EventPlanId=" + EventPlanId);

      if (validate1 && MemberId != "" && EventPlanId != "") {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Selected member details</b>";
        htmlTxt += "<br/>Member Name <b>" + selectedMember.MemberName + "</b>";
        htmlTxt += "<br/>Mobile no. <b>" + selectedMember.MobileNo + "</b>";
        htmlTxt += "<br/>Email id <b>" + selectedMember.EmailId + "</b>";
        htmlTxt +=
          "<br/><img width='100' height='100' src='" +
          selectedMember.MemberImagePath +
          "' />";
        htmlTxt += "<br/><b>Selected plan details</b>";
        htmlTxt += "<br/>Plan name <b>" + selectedPlan.PlanName + "</b>";
        htmlTxt +=
          "<br/>Total amount <b>" + selectedPlan.TotalAmountTxt + "</b>";
        htmlTxt += "<br/><br/>Do you want to continue?";
        Swal.fire({
          title: "Please verify all the information before proceeding",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
            // this.makePayment();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == "") {
          message += "Kindly select member. ";
        }
        if (EventPlanId == "") {
          message += "Kindly select plan. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var selectedPlan = this.selectedPlan;
      var selectedMember = this.selectedMember;
      console.log(
        "validate1=" +
          validate1 +
          ", selectedPlan=" +
          JSON.stringify(selectedPlan) +
          ", selectedMember=" +
          JSON.stringify(selectedMember)
      );

      var MemberId = selectedMember.MemberId;
      MemberId = MemberId == (null || undefined) ? "" : MemberId;
      console.log("MemberId=" + MemberId);

      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log("EventPlanId=" + EventPlanId);

      var participants = [];
      participants.push(selectedMember);

      var TotalCount = participants.length;
      console.log("TotalCount=" + TotalCount);

      var EventTotalAmount = selectedPlan.TotalAmount;
      console.log("EventTotalAmount=" + EventTotalAmount);

      var TotalAmount = parseFloat(TotalCount) * parseFloat(EventTotalAmount);
      console.log("TotalAmount=" + TotalAmount);

      if (validate1 && TotalCount > 0 && TotalAmount > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/order/create";
        var upload = {
          UserInterface: 1,
          RegistrationType: this.RegistrationTypeId,
          Lom: this.LomCode,
          Member: MemberId,
          Event: this.EventId,
          Plan: EventPlanId,
          MobileNo: selectedMember.MobileNo,
          EmailId: selectedMember.EmailId,
          Gender: selectedMember.Gender,
          FoodPreference: selectedMember.FoodChoice,
          GstNumberFlag: selectedMember.GstNumberFlag,
          GstNumber:
            selectedMember.GstNumberFlag == 1 ? selectedMember.GstNumber : null,
          TshirtSize: this.TshirtSize,
          PassportNumber: this.PassportNumber,
          SenatorNumber: this.SenatorNumber,
          TotalCount: TotalCount,
          TotalAmount: TotalAmount,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        // this.$session.set("checkout", upload);
        // this.checkout();

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              /*
              thisIns.sweetAlert(
                "info",
                "You will be redirected to checkout page shortly",
                true
              );
              */
              thisIns.Order = records.Order;
              thisIns.makePayment();
            } else {
              // thisIns.toast("error", output);
              // thisIns.Order = records.Order;
              // thisIns.makePayment();
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalCount == 0) {
          message += "Kindly select member. ";
        }
        if (TotalAmount == 0) {
          message += "Kindly select a plan. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    makePayment() {
      console.log("makePayment");
      // ev.preventDefault();
      var order = this.Order;
      console.log({ order });
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        // callback_url: "http://domain.com/#/about/" + this.order_id,
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.SubmitFlag = true;
          // thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/order/success");
        },
      });
      rzp1.open();
    },
  },
  beforeMount() {
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#natcon-payment-create {
  @import url(https://fonts.googleapis.com/css?family=Lato:700);
  $color: #8950fc;
  $color1: #191970;
  .cards {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    flex: 1;
    margin: 8px;
    padding: 10px;
    padding-top: 35px;
    height: 400px;
    width: 225px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    border: solid 1px #4f1990;
  }
  .cards-box {
    height: 380px;
    margin-top: 10px;
  }
  .cards.popular {
    margin-top: -10px;
    height: 400px;
    padding: 20px;
    padding-top: 40px;
    width: 225px;
  }
  .cards-title-bar {
    height: 30px;
  }
  .cards-title-bar .cards-title {
    color: $color1;
    font-size: 18px;
    vertical-align: middle;
    text-transform: uppercase;
  }
  .cards.popular .cards-title-bar .cards-title {
    color: $color;
    vertical-align: middle;
    font-size: 20px;
  }
  .cards-price .cards-price-amount {
    color: $color1;
    font-size: 30px;
  }
  .cards.popular .cards-price .cards-price-amount {
    color: $color;
    font-size: 32px;
  }
  .cards.popular .cards-action .cards-action-button {
    background-color: $color;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    height: 60px;
  }
  .cards.popular .cards-action .cards-action-button:hover {
    background-color: $color;
    font-size: 20px;
  }
  .cards:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  .cards-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
  }
  .cards-ribbon span {
    position: absolute;
    z-index: 1;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }
  .cards-ribbon::before,
  .cards-ribbon::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .cards-ribbon::before {
    top: 0;
    right: 0;
  }
  .cards-ribbon::after {
    bottom: 0;
    left: 0;
  }
  .cards-title-bar .cards-subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards.popular .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards-price .cards-price-amount .cards-price-sypol {
    font-size: 17px;
    display: inline-block;
    margin-left: -20px;
    width: 10px;
    color: rgba(0, 0, 0, 0.3);
  }
  .cards-price .cards-price-amount .cards-price-validity {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }
  .cards-price .cards-price-amount .cards-price-gst {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 14px;
    text-transform: uppercase;
  }
  .cards-description .cards-description-content-bar {
    display: block;
    list-style: none;
    margin: 40px 0 25px 0;
    padding: 0;
  }
  .cards-description .cards-description-content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: left;
    margin: 0 0 10px;
  }
  .cards-description .cards-description-content::before {
    content: "♦";
    padding: 0 5px 0 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
  .cards-action .cards-action-button {
    background: transparent;
    border: 2px solid $color;
    border-radius: 30px;
    color: $color;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .cards-action .cards-action-button:hover {
    background-color: $color;
    box-shadow: 0 2px 4px #4f1990;
    color: #fff;
    font-size: 17px;
  }
}
</style>
